<template>
  <div class="password-container">
    <el-card>
      <div slot="header" class="clearfix">
        <span>修改密码</span>
      </div>
      <el-form :model="form" :rules="rules" status-icon ref="form">
        <el-form-item prop="password">
          <el-input type="password" v-model="form.password" auto-complete="off" placeholder="旧密码"
            prefix-icon="el-icon-key"></el-input>
        </el-form-item>
        <el-form-item prop="newPassword">
          <el-input type="password" v-model="form.newPassword" auto-complete="off" placeholder="新密码"
            prefix-icon="el-icon-key"></el-input>
        </el-form-item>
        <el-form-item prop="comfirmPassword">
          <el-input type="password" v-model="form.comfirmPassword" auto-complete="off" placeholder="确认新密码"
            prefix-icon="el-icon-key"></el-input>
        </el-form-item>
        <el-form-item style="width:100%;">
          <el-button type="primary" style="width:100%;" @click="handleSubmit" :loading="Submiting"
            :disabled="!canSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  import {
    createNamespacedHelpers
  } from "vuex"
  const admin = createNamespacedHelpers('admin')
  export default {
    data() {
      return {
        Submiting: false,
        form: {
          password: '',
          newPassword: '',
          comfirmPassword: ''
        },
        rules: {
          password: [{
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('不能为空'))
              }
            },
            trigger: 'blur'
          }],
        },
      }
    },
    computed: {
      canSubmit() {
        return this.form.password && this.form.newPassword && this.form.newPassword == this.form.comfirmPassword
      }
    },
    methods: {
      ...admin.mapActions(['changePassword']),
      handleSubmit() {
        var data = {
          CurrentPassword: this.form.password,
          NewPassword: this.form.newPassword
        }
        this.changePassword(data)
          .then(res => {
            this.$message({
              type: 'success',
              message: res
            })
            this.resetForm()
          })
          .catch(err => {
            this.$message.error('修改失败:' + err.response.data)
          })
      },
      resetForm() {
        this.$refs['form'].resetFields();
      }
    }
  }
</script>

<style>
  .password-container {
    position: absolute;
    top: 30%;
    left: calc(50% - 150px)
  }
</style>